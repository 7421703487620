import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../../../hooks/useSiteMetadata'
import {FunctionComponent} from 'react'

type Props = {
  pageTitle: ?string
}

const SEO = ({pageTitle}: Props): FunctionComponent => {
  const { title, description } = useSiteMetadata()

  const transformedTitle = pageTitle && pageTitle.length > 0 ?
    title + ' - ' + pageTitle :
    title

  return (
    <Helmet>
      <html lang="hu" />
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta property="og:title" content={transformedTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta name="facebook-domain-verification" content="jg6fy65uks2h25ar3udd5xpbzwz4wn" />
      <title>{transformedTitle}</title>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Ubuntu:wght@700&display=swap" rel="stylesheet" />
    </Helmet>
  )
}

export default SEO
