import React from 'react'
import Layout from '../components/_global/layouts/Layout'
import notFoundImage from '../images/not-found-image.svg'
import {FunctionComponent} from 'react'

const ErrorPage = (): FunctionComponent => {
  return (
    <Layout>
      <main data-test-id="404" className="max-w-4xl flex-grow mx-auto flex flex-col justify-around">
        <img className="p-4" src={notFoundImage} alt="Page not found" />
      </main>
    </Layout>
  )
}

export default ErrorPage