import React, {FunctionComponent} from 'react'

type Props = {
  bgClass: string
}

const Footer = ({bgClass}: Props): FunctionComponent => {

  const footerLinkClass = 'hover:underline mb-4 border-white sm:pr-4 mx-2 sm:border-r '

  return (
    <div className={`${bgClass} text-white w-full py-12 px-2`}>
      <div className="flex flex-wrap justify-center flex-col text-center sm:flex-row sm:text-left">
        <a className={footerLinkClass} href="/doc/cookie-tajekoztato.pdf" target="_blank">Cookie (süti) tájékoztató</a>
        <a className={footerLinkClass} href="/doc/adatkezeles.pdf" target="_blank">Adatkezelési tájékoztató</a>
        <a className={footerLinkClass} href="/doc/aszf.pdf" target="_blank">Általános Szerződési Feltételek</a>
        <a className={footerLinkClass} href="/doc/szerzoi-jogok.pdf" target="_blank">Szerzői jogok</a>
        <a className={footerLinkClass} href="/doc/impresszum.pdf" target="_blank">Impresszum</a>
        <a className="hover:underline mb-4" href="mailto:info@mokajandek.hu">info@mokajandek.hu</a>
      </div>
      <div className="text-center">&copy; Mókajándék - Bereczky Balázs Gellért Ev.</div>
    </div>
  )
}

Footer.defaultProps = {
  bgClass: 'bg-purple'
}

export default Footer