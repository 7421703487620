import React from 'react'
import SEO from './SEO'
import {FunctionComponent} from 'react'
import * as PropTypes from 'prop-types'
import Footer from './Footer'

const CookieConsent = React.lazy((): any =>
  import('../CookieConsent')
)

type Props = {
  children: FunctionComponent,
  pageTitle: ?string,
  className: ?string,
  footerBgClass: ?string
}

const Layout = ({children, pageTitle, className, footerBgClass}: Props): FunctionComponent => {

  const isSSR = typeof window === 'undefined'

  return (
    <>
      <SEO pageTitle={pageTitle} />
      <div className={`flex flex-col min-h-screen bg-gray-200 ${className}`}>
        {children}
        <Footer bgClass={footerBgClass} />
      </div>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <CookieConsent />
        </React.Suspense>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
